<template>
	<div>
		<a-spin :spinning="loading">
			<div class="flex alcenter">
				<img v-if="account.face!=null" style="width: 60px;height: 60px; border-radius: 30px;" :src="account.face" />
				<img v-else style="width: 60px;height: 60px; border-radius: 30px;" src="../assets/image/woyaoyou_head_defult@2x.png" />
				<div class="ml15 flex alcenter">
					<div class="ft14 cl-main ftw600">{{account.username}}</div>
					<a-button @click="showPwd = true" type="link">修改密码</a-button>
					<!-- <div class="mt8 ft12 cl-notice">{{showT}}</div> -->
				</div>
			</div>
			<div class="mt40 flex alcenter">
				<span class="ft20 ftw600 cl-main cl-table" :class="[tableSwitchIndex === 0 ? 'active' : '']" @click="tableSwitch(0)">我的商铺({{mendians.length}})</span>
				<span class="ft20 ftw600 cl-main cl-table" :class="[tableSwitchIndex === 1 ? 'active' : '']" @click="tableSwitch(1)">商铺数据</span>
				<span class="ft14 cl-notice ml10" v-if="tableSwitchIndex === 0">每个商铺为独立运营的，会员是不通用的。每个商铺可以邀请合伙人一起管理</span>
				<span class="ft14 cl-notice ml10" v-if="tableSwitchIndex === 1">默认展示当天所有门店汇总数据</span>
			</div>
			<div class="mt30 mendian-list" v-if="tableSwitchIndex === 0">
				<div v-for="(item,index) in mendians" :key="index" @click="manageAct(item)" :class="{dis:item.shop == null}" class="mendian-item flex space" >
					<div>
						<div class="flex alcenter">
							<div class=" ft16 ftw600 cl-main text-over4">
								{{item.shop == null ? '该门店已注销' :  item.shop.name}}
							</div>
							<div class="ft14 ftw400 cl-notice ml5 text-over4">
								({{item.shop == null ? '' :  item.shop.application == null ? '' : item.shop.application.name}})
							</div>
						</div>
						<div class="mt12 ft12 cl-info">开通时间：{{item.add_time_format}}</div>
					</div>
					<div class="tag" v-if="item.role_id==1">创始人</div>
					<div class="tag" v-if="item.role_id==2">合伙人</div>
				</div>
				
				<div @click="addMendian" class="mendian-item add flex alcenter center">
					<i class="iconfont iconadd_big cl-theme"></i>
					<span class="ft16 cl-theme ftw600 ml10">创建商铺</span>
				</div>
			</div>

			<div v-if="tableSwitchIndex === 1">
				<div class="mt20 mb20">
					<div class="form-search-box ">
						<a-form layout="inline">
							<a-form-item label="日期">
								<a-range-picker :disabledDate="disabledDate" @change="changeOperateTime" v-model="operate_time" valueFormat="YYYY-MM-DD" />
							</a-form-item>
							<a-form-item>
								<a-select
									v-model="search.shopId"
									show-search
									placeholder="请选择"
									option-filter-prop="children"
									style="width: 300px">
									<a-select-option :value="item.shop_id" v-for="(item, index) in shopDataList" :key="index">
										{{ item.name }}
									</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item>
								<a-button @click="searchAct" type="primary">查询</a-button>
								<a-button @click="resetData" class="ml10">重置</a-button>
							</a-form-item>
						</a-form>
					</div>
				</div>

				<a-row :gutter="16" class="mb20">
					<a-col :span="6">
						<div class="report-form-item">
							<div class="item-text">营业额</div>
							<div class="item-info"><span>￥</span>{{ shopDataReport.payAmount }}</div>
						</div>
					</a-col>
					<a-col :span="6">
						<div class="report-form-item">
						<div class="item-text">订单数</div>
						<div class="item-info">{{ shopDataReport.orderNumber }}<span style="padding-left:5px;">单</span></div>
						</div>
					</a-col>
					<a-col :span="6">
						<div class="report-form-item">
							<div class="item-text">新增会员数</div>
							<div class="item-info">{{ shopDataReport.newMemberNumber }}<span style="padding-left:5px;">人</span></div>
						</div>
					</a-col>
					<a-col :span="6">
						<div class="report-form-item">
							<div class="item-text">预约人数</div>
							<div class="item-info">{{ shopDataReport.appointmentMemberCount }}<span style="padding-left:5px;">人</span></div>
						</div>
					</a-col>
				</a-row>

				<a-row :gutter="16" class="mb20">
					<a-col :span="6">
						<div class="report-form-item">
							<div class="item-text">会员充值</div>
							<div class="item-info"><span>￥</span>{{ shopDataReport.rechargeBalance }}</div>
						</div>
					</a-col>
					<a-col :span="6">
						<div class="report-form-item">
							<div class="item-text">办卡金额</div>
							<div class="item-info"><span>￥</span>{{ shopDataReport.onceCardAmount }}</div>
						</div>
					</a-col>
					<a-col :span="6">
						<div class="report-form-item">
							<div class="item-text">收银台支付</div>
							<div class="item-info"><span>￥</span>{{ shopDataReport.cashierOrderPayMoney }}</div>
						</div>
					</a-col>
					<a-col :span="6">
						<div class="report-form-item">
							<div class="item-text">商城订单金额</div>
							<div class="item-info"><span>￥</span>{{ shopDataReport.orderPayMoney }}</div>
						</div>
					</a-col>
				</a-row>

				<a-row :gutter="16" class="mb20">
					<a-col :span="6">
						<div class="report-form-item">
							<div class="item-text">办卡人数</div>
							<div class="item-info">{{ shopDataReport.onceCardMemberCount }}<span style="padding-left:5px;">人</span></div>
						</div>
					</a-col>
					<a-col :span="6">
						<div class="report-form-item">
							<div class="item-text">次卡核销</div>
							<div class="item-info">{{ shopDataReport.onceCardWriteOffMember }}<span style="padding-left:5px;">人</span></div>
						</div>
					</a-col>
				</a-row>
			</div>
		</a-spin>
		
		<a-modal v-model="showPwd" title="设置密码" on-ok="handleOk">
			<template slot="footer">
			<a-button key="back" @click="handlePwdCancel">
				取消
			</a-button>
			<a-button key="submit" type="primary" :loading="pwdloading" @click="handlePwdOk">
				确认
			</a-button>
			</template>
			<a-form-model :model="pwd" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item label="输入密码">
				<a-input type="password" v-model="pwd.pwd" />
				</a-form-model-item>
				<a-form-model-item label="再次确认">
				<a-input type="password"  v-model="pwd.repwd" />
				</a-form-model-item>
			</a-form-model>	  
		</a-modal>
	</div>
</template>

<script>
	import moment from 'moment'
	export default{

		data(){
			return {
				labelCol: { span: 4 },
				wrapperCol: { span: 14 },
				loading:false,
				showPwd:false,
				pwd:{
					pwd:'',
					repwd:'',
				},
				pwdloading:false,
				account:{
					mobile:'',
					username:'',
					face:null,
				},
				mendians:[],
				// 商铺数据
				operate_time: [moment().subtract(0, 'days').format('YYYY-MM-DD'), moment().subtract(0, 'days').format('YYYY-MM-DD')],
				shopDataList: [],
				shopDataReport: '',
				tableSwitchIndex: 0,
				search: {
					startDate: '',
					endDate: '',
					shopId: undefined
				},
				disabledDate (current) {
					return current && current > moment().endOf('day')
				}
			}
		},
		
		created(){
			this.search = {
				startDate: this.operate_time[0],
				endDate: this.operate_time[1]
			},
			this.getLists();
			this.getShopDataList();
			this.getShopDataReport();
		},
		methods:{
			handlePwdCancel(){
				this.showPwd = false;
			},
			handlePwdOk(){
				if(this.pwdloading == true) return;
				this.pwdloading = true;
				this.$http.api('platform/account/editPwd',{
					pwd:this.pwd.pwd,
					repwd:this.pwd.repwd
				}).then(res=>{
					this.pwdloading = false;
					this.$message.success('密码设置成功');
					this.showPwd = false;
				}).catch(res=>{
					this.pwdloading = false;
				});
			},
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/account/home').then(res=>{
					this.account=res.account;
					this.mendians=res.mendian;
					if(this.account.password == null || this.account.password == ''){
						this.showPwd = true;
					}
				}).catch(res=>{
					console.log(res);
				}).finally(()=>{
					this.loading=false;
				})
			},
			
			manageAct(item){
				if(item.shop == null){
					this.$message.error('该门店已注销');
					return false;
				}
        let base_url = item.shop.application.development_address;
        //let base_url = 'http://192.168.1.3:9190/beautiful/#/'
				window.open(base_url  + 'auth?shop_token='+ encodeURIComponent(item.shop_token));
			},
			handleOk(){
				this.showForm = false;
			},
			handleCancel(){
				this.showForm = false;
			},
			addMendian(){
				// this.showForm = true;
				this.$router.push('/mendian/create');
			},
			
			/*
			* 商铺数据
			*/
			// 选择时间
			changeOperateTime (val) {
				this.search.startDate = val[0]
				this.search.endDate = val[1]
			},
			// 获取店铺列表
			getShopDataList () {
				this.shopDataList = []
				this.$http.api('platform/account/shopList').then(res=>{
					this.shopDataList = res.shopList
				}).catch(res=>{
					console.log(res);
				})
			},
			// 获取商铺数据
			getShopDataReport () {
				this.shopDataReport = ''
				this.$http.api('platform/account/shopDataReport', {
					startDate: this.search.startDate,
					endDate: this.search.endDate,
					shop_id: this.search.shopId
				}).then(res=>{
					this.shopDataReport = res
				}).catch(res=>{
					console.log(res);
				})
			},
			// 查询
			searchAct () {
				this.getShopDataReport()
			},
			// 重置
			resetData () {
				this.operate_time = [moment().subtract(0, 'days').format('YYYY-MM-DD'), moment().subtract(0, 'days').format('YYYY-MM-DD')]
				this.search = {
					startDate: this.operate_time[0],
					endDate: this.operate_time[1],
					shop_id: undefined
				}
				this.searchAct()
			},

			tableSwitch (index) {
				this.tableSwitchIndex = index
			}
		}
	}
</script>

<style>
	.mendian-list{
		display: grid;
		grid-template-columns: repeat(3,380px);   
		grid-template-rows: repeat(3,100px); 
		grid-column-gap: 30px; 
		grid-row-gap: 30px;
	}
	.mendian-item{
		width: 380px;
		height: 100px;
		background: #FFFFFF;
		border-radius: 8px;
		border-left: 8px solid #EBEDF5;
		padding: 24px 16px;
		cursor: pointer;
		transition: all 0.3s;
	}
	.mendian-item.dis{
		opacity: 0.5;
	}
	.mendian-item .tag{
		width: 52px;
		height: 20px;
		border-radius: 10px;
		border: 1px solid #4772FF;
		text-align: center;
		line-height: 18px;
		font-size: 12px;
		color:#4772FF;
	}
	.mendian-item:hover{
		transform: translateY(-10px);
	}
	.mendian-item.add{
		border: 1px dashed #4772FF;
	}
	.report-form-item {
		height: auto;
		background: #fff;
		border-radius: 7px;
		padding: 30px;
		position: relative;
	}
	.report-form-item .item-text {
		color: #212C68;
		font-size: 20px;
	}
	.report-form-item .item-info {
		color: #212C68;
		font-size: 30px;
		padding-top: 15px;
		overflow: hidden;
	}
	.report-form-item .item-info span {
		font-size: 16px;
		color: #212C68;
	}
	.cl-table {
		cursor: pointer;
		margin-right: 30px;
		padding-bottom: 10px;
	}
	.cl-table.active {
		color: #4772FF;
		border-bottom: 2px solid #4772FF;
	}
</style>